<template>
  <v-card
    v-bind="$attrs"
    v-on="$listeners"
    flat
    :ripple="false"
    :class="['style-card d-flex relative', data.is_viewed ? 'viewed' : '']"
    :to="navigationLink"
  >
    <div class="style-card-img flex-shrink-0">
      <UIImage class="default-image" v-if="!data?.item?.file" name-path="default-img.png"/>
      <img v-else-if="$config.filesystem.fileTypes.image.includes(getFileExtension(data?.item?.file?.original_name))" :src="getFileThumbnails(data.item.file.thumbnails) || data.item.file.url" :alt="data.project.title"/>
      <UiFileIcon v-else width="17" :extension="getFileName(data?.item?.file.original_name).ext"/>
    </div>
    <div class="content d-flex justify-space-between">
      <div class="content-center d-flex flex-column mr-2">
        <div class="content-center-top d-flex align-center mb-1">
          <div v-if="data?.item?.style?.title" class="text-body gray-100--text mr-2 text-truncate" :class="[data.is_viewed ? '' : 'font-weight-bold']">{{ data?.item?.style?.title }}</div>
          <UiProjectModuleIcon width="14" :icon="data.icon" class="module-icon mr-1 flex-shrink-0 gray-60--text"/>
          <div class="text-captions-1 gray-60--text mr-3 text-truncate">{{data.title}}</div>
          <v-tooltip top color="accent" v-if="data.item?.version">
            <template v-slot:activator="{ on, attrs }">
              <strong v-bind="attrs" v-on="on" class="d-flex align-center text-captions-2"><span class="text-gray-80 mr-1">V:</span> <span class="accent-100--text">{{data.is_multiple ? 'multiple' :  data.item?.version }}</span></strong>
            </template>
            <span class="white--text font-weight-medium">{{ data.is_multiple ? 'multiple' : 'V'+ data?.item?.version }}</span>
          </v-tooltip>
        </div>
        <v-tooltip top color="accent">
          <template v-slot:activator="{ on, attrs }">
                <span class="gray-100--text text-captions-2 text-truncate" v-bind="attrs"
                      v-on="on">{{ data.project.title }}</span>
          </template>
          <span class="white--text font-weight-medium ">{{ data.project.title }}</span>
        </v-tooltip>
        <div class="mt-1 d-flex align-center">
          <UIChip class="mr-3" chipHeight="18px" showDot :text-color="data.space.color" :backgroundColor="data.space.color">
            <span class="text-captions-2 font-weight-bold">{{ data.space.title }}</span>
          </UIChip>
          <UiProjectStatus :id="data.status" statusType="project" asSlot>
            <template v-slot="{ selectedStatus }">
              <div class="d-flex align-center" v-if="selectedStatus">
                <UIChip
                  chipHeight="18px"
                  :text-color="selectedStatus.color"
                  outlined
                  :color="selectedStatus.color"
                  chipMinWidth="100px"
                >
                  <span class="text-captions-2 font-weight-bold">{{ selectedStatus.title }}</span>
                </UIChip>
                <IconLayers v-if="data.is_multiple" width="16" class="ml-2" :class="[`${selectedStatus.color}--text`]"/>
              </div>
            </template>
          </UiProjectStatus>

        </div>
      </div>
      <div class="d-flex align-center">
        <UiBtn v-if="data?.item?.file && !data.is_multiple" color="gray-60" icon class="mr-2" @click.prevent="downloadFile(data.item.file)">
          <IconDownload width="16"/>
        </UiBtn>
        <UiBtn
          icon
          color="gray-60"
          class="mr-2"
          @click.prevent
          :to="navigationLinkWithChat"
        >
          <IconChat width="16" class="mt-1"/>
        </UiBtn>
        <StyleCardMenu :project="data.project" :module="data" :getPendingApproval="getPendingApproval" :getAssetsPending="getAssetsPending" @setLoading="loading = $event" />
      </div>
    </div>
    <div v-if="loading" class="absolute inset-0 d-flex justify-center align-center gray-30"
         style="z-index: 2;" @click.prevent.self>
      <v-progress-circular
        :size="48"
        color="accent"
        indeterminate
      />
    </div>
  </v-card>
</template>

<script>
import UIImage from "@/components/UI/UIImage.vue";
import {getFileThumbnails, getFileExtension, getFileName} from "@/utils/helpers";
import UiProjectModuleIcon from "@/components/UI/UiProjectModuleIcon.vue";
import UIChip from "@/components/UI/UIChip.vue";
import UiBtn from "@/components/UI/UiBtn.vue";
import StyleCardMenu from "@/views/globalDashboard/StyleCardMenu.vue";
import UiProjectStatus from "@/components/UI/UiProjectStatus.vue";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";

export default {
  name: "StyleCard",
  components: {
    UiFileIcon,
    UiProjectStatus,
    IconDownload: () => import("@/components/icons/IconDownload"),
    IconChat: () => import("@/components/icons/IconChat"),
    UiBtn,
    UIChip,
    UiProjectModuleIcon,
    UIImage,
    StyleCardMenu,
    IconLayers: () => import("@/components/icons/IconLayers")
  },
  props: {
    projectStyle: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    getPendingApproval: {
      type: Function,
      default: () => {},
    },
    getAssetsPending: {
      type: Function,
      default: () => {},
    },
    status: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      module: {
        icon: "IconPalette",
        name: 'Sketch Design'
      },
      space: {
        color: 'in_progress'
      },
      unreadMessages: true,
      loading: false
    };
  },
  computed: {
    navigationLink() {
     return this.getNavigationLink(false)
    },
    navigationLinkWithChat() {
      return this.getNavigationLink(true)
    },
  },
  methods: {
    getFileThumbnails,
    getFileExtension,
    getFileName,
    async downloadFile(file) {
      if (this.fileLoading) return;
      this.fileLoading = true;

      const image = await fetch(file.download_url || file.url);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = file.download_file_name || file.original_name;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(imageURL);
      this.fileLoading = false;
    },
    getNavigationLink(withChat = false) {
      if(this.data.is_multiple && !withChat) {
        return {
          name: 'GlobalDashboardAll',
          query: {expand_id: this.data.id, status: this.status, hide_viewed: 1}
        }
      } else if(!this.data.item || (this.data.is_multiple && withChat)) {
        return {
          name: 'ProjectDesign',
          params: {
            project_id: this.data.project.id
          },
          query: {
            module: this.data.id,
            open_project_chat: withChat ? 1 : undefined
          }
        }
      } else {
        return {
          name: 'DesignViewPage',
          params: {
            project_id: this.data.project.id
          },
          query: {
            module_id: this.data.id,
            style_id: this.data?.item?.style.id,
            file_id: this.data?.item?.file?.id,
            gallery_group_id: this.data?.item?.gallery_group_id,
            open_project_chat: withChat ? 1 : undefined
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.style-card {
  padding: 10px;
  border-radius: 2px;
  border: 1px solid var(--v-gray-30-base);
  background: var(--v-gray-0-base);
  &.viewed {
    background: var(--v-gray-10-base);
    border: 1px solid var(--v-gray-10-base);
  }
}
.style-card-img {
  width: 54px;
  height: 54px;
  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.default-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content {
  width: 100%;
  overflow: hidden;
}
.content-center {
  overflow: hidden;
}
.content-center-top {
  width: 100%;
}
</style>
